<script>
	import { onDestroy, onMount } from "svelte";
	import { playSound } from "../services/audio";
	import { dbPage } from "../services/database";
	import { usersStore } from "../store";
	import { LobbyScreen } from "da-components/v0";
	import Header from "../components/Header.svelte";
	import { preLoadAssets } from "../services/utils";
	export let isHost;
	export let hostId;
	export let hostName = "";
	let onlineUsers = [];
	let offlineUsers = [];
	let noOfOnlinePlayers;
	let users = [];
	const usersStoreUnsubscribe = usersStore.subscribe(value => {
		users = Object.values(value);
	});

	$: {
		if (users) {
			onlineUsers = [];
			offlineUsers = [];
			noOfOnlinePlayers = 0;
			for (const id in users) {
				if (users[id]?.online === true) {
					onlineUsers.push(users[id]);
					noOfOnlinePlayers += 1;
				} else if (users[id]?.online !== true) {
					offlineUsers.push(users[id]);
				}
			}
		}
	}
	const changePageToChooseMode = () => {
		dbPage().set("CHOOSE_MODE");
	};
	onMount(async () => {
		preLoadAssets("CHOOSE_MODE");
		preLoadAssets("GAME");
	});
	onDestroy(() => {
		usersStoreUnsubscribe();
	});
	// console.log(isHost);
</script>

<div class="lobbyRoot">
	<div class="headerWrapper">
		<Header {isHost} />
	</div>
	<div class="lobbyWrapper">
		<LobbyScreen
			{onlineUsers}
			{offlineUsers}
			handleStartGame={changePageToChooseMode}
			{isHost}
			onMouseDown={() => playSound("CLICK")}
			bottomText="Great for quick energisers with large groups. Don't forget to yell Bingo!"
			{hostId}
			{hostName}
		/>
	</div>
</div>

<style>
	.lobbyRoot {
		width: 100%;
		height: 100%;
		display: flex;
		background-image: url("/images/backgrounds/background-svg.svg");
		background-position: center;
		background-color: var(--base);
		background-size: cover;
	}

	.lobbyWrapper {
		width: 100%;
		font-size: 24px;
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
	}
	.headerWrapper {
		z-index: 2;
	}

	@media screen and (max-height: 650px) {
		.lobbyWrapper {
			position: static;
			height: 100%;
			transform: translate(0, 0);
			margin-top: 5rem;
		}
	}
</style>
