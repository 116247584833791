<script>
	import Card from "./Card.svelte";
	export let disabled = false;
	let rightVisible = false,
		leftVisible = true;
	let goLeft;
</script>

<div class="hardModeWrapper">
	<div class:goLeft><Card key={0} {disabled} /></div>
	<div class:goLeft><Card key={1} {disabled} /></div>
	<span
		class="left"
		class:leftVisible
		on:click={() => {
			leftVisible = true;
			rightVisible = false;
			goLeft = false;
		}}
	>
		{"<"}
	</span>
	<span
		class="right"
		class:rightVisible
		on:click={() => {
			leftVisible = false;
			rightVisible = true;
			goLeft = true;
		}}>{">"}</span
	>
</div>

<style>
	.hardModeWrapper {
		width: 98%;
		margin: auto;
		transition: transform 0.5s linear;
		display: flex;
		/* overflow: hidden; */
		position: relative;
		gap: 0.3rem;
	}
	.hardModeWrapper div {
		min-width: max-content;
		/* position: absolute; */
		transition: transform 0.5s linear;
		flex: 1 0 0;
		margin-left: 0%;
	}
	.goLeft {
		transform: translate(-100%);
	}

	.left,
	.right {
		font-size: 24px;

		cursor: pointer;
		display: flex;
		justify-content: center;
		align-items: center;
		top: -8%;
		position: absolute;
		border: 3px solid rgba(10, 10, 10, 0.35);
		transform: translate(0, -50%);
		/* 2dp — Elevation */
		filter: drop-shadow(0px 2px 4px rgba(0, 0, 0, 0.14)) drop-shadow(0px 3px 4px rgba(0, 0, 0, 0.12))
			drop-shadow(0px 1px 5px rgba(0, 0, 0, 0.2));
		border-radius: 24px;
		background: linear-gradient(180deg, rgba(0, 0, 0, 0.25) 0%, rgba(255, 255, 255, 0) 100%), #9002ff;
		background-blend-mode: soft-light, normal;
		border-radius: 24px;
		height: 48px;
		width: 48px;
	}

	.left {
		left: 0;
	}
	.right {
		right: 0;
	}
	.leftVisible {
		display: none;
	}
	.rightVisible {
		display: none;
	}
</style>
