<script>
	import { playSound } from "../services/audio";
	import { dbCountdownTimer, dbGameMode, dbPage } from "../services/database";
	import { PrimaryButton } from "da-components/v0";
	import Header from "../components/Header.svelte";
	import { onMount } from "svelte";
	import { preLoadAssets } from "../services/utils";
	export let isHost;
	export let hostName = "";
	let upOrDownEasy = true;
	$: upEasy = !upOrDownEasy;
	$: downEasy = upOrDownEasy;

	let upOrDownHard = true;
	$: upHard = !upOrDownHard;
	$: downHard = upOrDownHard;
	let chosenMode;

	onMount(async () => {
		preLoadAssets("GAME");
	});
	const handleStartGame = async () => {
		if (!chosenMode) {
			return;
		}
		window?.DIVE_APP?.sendEvent({
			type: "choose_mode",
			payload: {
				mode: chosenMode,
			},
		});
		await dbGameMode().set(chosenMode);
		await dbPage().set("TIMER");
		await dbCountdownTimer().set(4);
	};
</script>

<div class="modeRoot">
	<div class="headerWrapper">
		<Header {isHost} />
	</div>
	<div class="modeWrapper">
		<div class="chooseMode">
			<p class="gameModeHeading">Game Mode</p>
			<div class="modeContainer">
				<div class="modes">
					<div
						class:isChosen={chosenMode === "easy"}
						class:isNotChosen={chosenMode && chosenMode !== "easy"}
						on:click={() => {
							if (isHost) {
								playSound("CLICK");
								chosenMode = "easy";
							}
						}}
						on:mouseover={() => (upOrDownEasy = false)}
						on:mouseleave={() => (upOrDownEasy = true)}
						on:focus={() => (upOrDownEasy = false)}
					>
						<div class:upEasy class="easy">
							<img src="./images/easyTicket.svg" alt="easy-bingo-ticket" />
							<p class="easyHeading">Easy</p>
						</div>
						<div class:downEasy class="easyInst">
							<p class="easyHeading">Easy</p>
							<p class="instructions">
								Best for beginners, the easy mode is a 1 ticket game. Balls are rolled out slowly &
								players get to see upto 5 previous draws!
							</p>
						</div>
					</div>
					<div
						class:isChosen={chosenMode === "hard"}
						class:isNotChosen={chosenMode && chosenMode !== "hard"}
						on:click={() => {
							if (isHost) {
								playSound("CLICK");
								chosenMode = "hard";
							}
						}}
						on:mouseover={() => (upOrDownHard = false)}
						on:mouseleave={() => (upOrDownHard = true)}
						on:focus={() => (upOrDownHard = false)}
					>
						<div class:upHard class="hard">
							<img src="./images/hardTicket.svg" alt="easy-bingo-ticket" />
							<p class="hardHeading">Hard</p>
						</div>
						<div class:downHard class="hardInst">
							<p class="hardHeading">Hard</p>
							<p class="instructions">
								Fancy an adventure? Play the game with 2 tickets! In addition, the balls roll out fast &
								players can see only 3 previous draws!
							</p>
						</div>
					</div>
				</div>
				<div class="startGameSection">
					{#if isHost === true}
						<PrimaryButton
							fontSize="16"
							disabled={!chosenMode}
							width="143"
							text="Start Game"
							height="45"
							onClick={handleStartGame}
							onMouseDown={() => playSound("CLICK")}
						/>
					{/if}
				</div>
			</div>
		</div>
		{#if isHost === false}
			<p class="hostChoosingModeText">
				{`waiting for ${hostName}(Host) to choose the mode`}
			</p>
		{/if}
	</div>
</div>

<style>
	.modeRoot {
		width: 100%;
		height: 100%;
		display: flex;
		background: var(--base);
		background-image: url("/images/backgrounds/background-svg.svg");
		background-position: center;
		background-size: cover;
	}
	.easy,
	.easyInst,
	.hard,
	.hardInst {
		position: absolute;
		transition: transform 0.5s;
		width: 100%;
		height: 100%;
	}
	.instructions {
		font-family: "Poppins";
		line-height: 18.2px;
		font-size: 14px;
		text-align: center;
	}
	.easy,
	.hard,
	.easyInst,
	.hardInst {
		display: flex;
		justify-content: space-evenly;
		align-items: center;
		flex-direction: column;
		padding: 10px;
	}
	.easyHeading {
		color: #ffc700;
		text-shadow: 0 0 3px #000;
		font-size: 24px;

		font-weight: 700;
	}
	.hardHeading {
		color: #f2681a;
		text-shadow: 0 0 3px #000;
		font-size: 24px;

		font-weight: 700;
	}

	.downHard,
	.downEasy {
		transform: translateY(100%);
	}
	.upHard,
	.upEasy {
		transform: translateY(-100%);
	}
	.gameModeHeading {
		font-weight: 700;
		font-size: 22px;
	}

	.modeWrapper {
		width: 100%;
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
	}

	.chooseMode {
		display: flex;
		justify-content: center;
		flex-direction: column;
		align-items: center;
		max-width: 679px;
		width: 92%;
		margin: auto;
		background: rgba(117, 144, 240, 1);
		padding: 12px;
		border: 2px solid rgba(10, 10, 10, 0.25);
		border-radius: 16px;
		-webkit-box-shadow: 1px 6px 16px -1px rgba(0, 0, 0, 0.59);
		box-shadow: 1px 6px 16px -1px rgba(0, 0, 0, 0.59);
		-moz-box-shadow: 1px 6px 16px -1px rgba(0, 0, 0, 0.59);
	}

	.modeContainer {
		display: flex;
		justify-content: space-between;
		flex-direction: row;
		width: 100%;
		background-color: #4058ae;
		margin-top: 10px;
		padding-top: 13px;
		padding-bottom: 13px;
		padding-left: 28px;
		padding-right: 28px;
		/* padding-bottom: 40px; */
		/* height: 293px; */
		align-items: center;
		flex-wrap: wrap;
		border-radius: 8px;
		border: 3px solid #0000001f;
	}
	.modes {
		display: flex;
		width: 100%;
		align-items: center;
	}
	.modes > div {
		flex: 1;
		height: 240px;
		border: 1px solid rgba(255, 255, 255, 0.25);
		position: relative;
		overflow: hidden;
		border-radius: 12.2055px;
		cursor: pointer;
	}
	.modes > * + * {
		margin-left: 1rem;
	}

	.startGameSection {
		margin: auto;
		margin-top: 16px;
		display: flex;
		justify-content: center;
	}

	.hostChoosingModeText {
		margin-top: 1rem;
		text-align: center;

		font-style: normal;
		font-weight: 700;
		font-size: 20px;
		line-height: 27px;
		text-shadow: 0px 2px 4px rgba(91, 26, 26, 0.14), 0px 3px 4px rgba(123, 12, 12, 0.12),
			0px 1px 5px rgba(136, 13, 13, 0.2);
	}

	.isChosen {
		background: rgba(255, 255, 255, 0.08);
		border: 1.01712px solid #ffffff;
		opacity: 1;
	}
	.isNotChosen {
		opacity: 0.5;
	}

	/* responsive code */
	@media screen and (max-width: 500px) {
		.modes {
			flex-direction: column;
		}

		.modes div {
			flex: unset;
			width: 100%;
			height: 150px;
		}
		.modes > * + * {
			margin: auto;
			margin-top: 1rem;
		}
		.modes img {
			width: 144px;
			height: 115px;
		}

		.easyHeading,
		.hardHeading {
			font-size: 18px;
		}

		.instructions {
			font-size: 12px;
		}

		.hostChoosingModeText {
			font-size: 14px;
			margin-top: 8px;
		}

		.gameModeHeading {
			font-size: 20px;
		}
	}
	@media screen and (max-height: 650px) {
		.modeWrapper {
			position: static;
			height: 100%;
			transform: translate(0, 0);
			margin-top: 110px;
		}
	}
	@media screen and (max-height: 700px) and (max-width: 500px) {
		.modeWrapper {
			position: static;
			height: 100%;
			transform: translate(0, 0);
			margin-top: 5rem;
		}
	}
</style>
