<script lang="ts">
	export let i = 0;
	export let j = 0;
	export let val = 0;
	export let state = "default";
	export let mini = false;
	$: selected = state === "selected";
	$: bingoAnimation = state === "animate";
	$: bingoed = state === "bingoed";
</script>

{#if i === 2 && j === 2}
	<div class:bingoAnimation class:mini>
		<div class:selected class:bingoed class="center">
			<!-- <p>:)</p> -->
			<img src="./images/Star.svg" alt="center star" />
		</div>
	</div>
{:else}
	<div class:bingoAnimation class:mini>
		<div class:selected class:bingoed>
			<p>{val}</p>
		</div>
	</div>
{/if}

<style>
	div {
		flex: 1 1 17%;
		height: auto;
		display: flex;
		justify-content: space-around;
		align-items: center;
		/* background-color: #3a3a3a; */
		color: white;
		transition: linear 0.1s;
	}

	.center > img {
		width: 65%;
		height: 65%;
	}

	.selected {
		background-color: #e6b91a;
		border-radius: 50%;
		color: #392f5a;
	}
	.bingoAnimation {
		/* background-color: green; */
		border-radius: 50%;
		transform: scale(1.3);
	}
	.bingoed {
		background-color: #f9850b;
		border-radius: 7px;
		color: white;
	}

	div p {
		text-align: center;
		font-size: 17px;

		font-weight: 700;
	}
	p {
		margin: 0;
	}
	div div {
		margin: 7px;
		height: 38px;
		/* padding: 1rem; */
		font-size: 21px;
	}

	div.mini div p {
		font-size: 12px;
	}

	div.mini div {
		height: 23px;
		margin: 4px;
	}
</style>
