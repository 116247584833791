import { get } from "svelte/store";
import { sound } from "../store";

const bgMusic = new Audio("/music/BgMusic.mp3");
const clickSound = new Audio("/music/Click.mp3");
const correctBingo = new Audio("/music/CorrectBingo.wav");
const wrongSound = new Audio("/music/Wrong.wav");
const timerToBingo = new Audio("/music/TimerToBingo.wav");
const bingoToLeaderboard = new Audio("/music/BingoToLeaderboard.wav");
const timer321 = new Audio("/music/Timer321.mp3");
const endGame = new Audio("/music/EndGame.mp3");
const bigCountdown = new Audio("/music/BigCountdown.wav");
const newBall = new Audio("/music/NewBall.wav");

bgMusic.loop = true;

export const playSound = type => {
	let value = get(sound);
	switch (type) {
		case "BGMUSIC":
			play(bgMusic, 0.1, value, false);
			break;
		case "BGMUSICSTOP":
			stop(bgMusic);
			break;
		case "CLICK":
			play(clickSound, 0.2, value);
			break;
		case "NEWBALL":
			play(newBall, 0.1, value);
			break;
		case "CORRECTBINGO":
			play(correctBingo, 0.2, value);
			break;
		case "WRONG":
			play(wrongSound, 0.01, value);
			break;
		case "ENDGAME":
			play(endGame, 0.1, value);
			break;
		case "TIMERTOBINGO":
			play(timerToBingo, 0.2, value);
			break;
		case "BINGOTOLEADERBOARD":
			play(bingoToLeaderboard, 0.2, value);
			break;
		case "TIMER321":
			play(timer321, 0.2, value, false);
			break;
		case "TIMER321STOP":
			stop(timer321);
			break;
		case "BIGCOUNTDOWN":
			play(bigCountdown, 0.2, value, false);
			break;
		case "BIGCOUNTDOWNSTOP":
			stop(bigCountdown);
			break;
		case "THREECOUNTDOWN":
			play(countdown, 0.2, value, false);
			break;
		case "THREECOUNTDOWNSTOP":
			stop(countdown);
			break;
		case "LEADERBOARDTRANSITION":
			play(LeaderboardTransition, 0.2, value);
			break;
		case "APPLAUSE":
			play(applause, 0.75, value);
			break;
		case "APPLAUSESTOP":
			stop(applause);
			break;
		case "MUTE":
			clickSound.volume = 0;
			correctBingo.volume = 0;
			wrongSound.volume = 0;
			timerToBingo.volume = 0;
			bingoToLeaderboard.volume = 0;
			bigCountdown.volume = 0;
			timer321.volume = 0;
			bgMusic.volume = 0;
			newBall.volume = 0;
			endGame.volume = 0;
			break;
		case "UNMUTE":
			clickSound.volume = 0.2;
			correctBingo.volume = 0.2;
			wrongSound.volume = 0.01;
			timerToBingo.volume = 0.2;
			bingoToLeaderboard.volume = 0.2;
			bigCountdown.volume = 0.2;
			timer321.volume = 0.2;
			bgMusic.volume = 0.1;
			newBall.volume = 0.1;
			endGame.volume = 0.1;
			break;
		default:
			break;
	}
};

const play = async (sound, volume, value, refresh = true) => {
	try {
		if (refresh) {
			await sound.pause();
			sound.currentTime = 0;
		}
		sound.volume = value ? volume : 0;
		await sound.play();
	} catch (error) {
		console.log(error, "Browser is preventing autoplay of music");
	}
};

const stop = async sound => {
	try {
		await sound.pause();
		sound.currentTime = 0;
	} catch (error) {
		console.log(error, "Browser is preventing autoplay of music");
	}
};
