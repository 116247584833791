import { announcementStore, coinAnimationStore, latestBallStore } from "../store";
import { v4 as uuidv4 } from "uuid";
import { crossfade } from "svelte/transition";
import { quintOut } from "svelte/easing";
import { info } from "./Notifier";
import { dbAnnouncements, dbGameSessionRoundValue, dbGameTimer, dbHostAction, getServerTimeOffset } from "./database";
import firebase from "firebase/app";
let announcementTimer = "";
let latestBall = "";
latestBallStore.subscribe(value => {
	latestBall = value;
});
export const params = new URLSearchParams(window.location.search);
export const getParams = function (name) {
	return "" + params.get(name);
};
export const getGameSessionId = function () {
	return `${getParams("roomId")}+${getParams("sessionId")}`;
};

export const secondsLeft = (duration, startAt) => {
	return ((duration - 5) * 1000 - (Date.now() - startAt - getServerTimeOffset())) / 1000;
};

export const msecondsSince = startAt => {
	return Date.now() - startAt;
};

export const checkBingo = cardTicket => {
	let bingoResult = {
		row_bingo: [],
		col_bingo: [],
		diagonal_bingo: [],
		corner_bingo: [],
		full_house: [],
		multiple_bingo: [],
	};

	//check row
	for (let i = 0; i < cardTicket.length; i++) {
		let bingo = true;
		let atLeastOneSelected = false;
		for (let j = 0; j < cardTicket[i].length; j++) {
			if (cardTicket[i][j].state !== "selected" && cardTicket[i][j].state !== "bingoed") {
				bingo = false;
				break;
			}
			if (cardTicket[i][j].state === "selected") {
				atLeastOneSelected = true;
			}
		}
		if (bingo === true && atLeastOneSelected) {
			bingoResult.row_bingo.push(i);
		}
	}

	//check col

	for (let i = 0; i < cardTicket.length; i++) {
		let bingo = true;
		let atLeastOneSelected = false;
		for (let j = 0; j < cardTicket[i].length; j++) {
			if (cardTicket[j][i].state !== "selected" && cardTicket[j][i].state !== "bingoed") {
				bingo = false;
				break;
			}
			if (cardTicket[j][i].state === "selected") {
				atLeastOneSelected = true;
			}
		}
		if (bingo === true && atLeastOneSelected) {
			bingoResult.col_bingo.push(i);
		}
	}
	//check corners
	let arrOfStates = [
		cardTicket[0][0].state,
		cardTicket[0][4].state,
		cardTicket[4][0].state,
		cardTicket[4][4].state,
		cardTicket[2][2].state,
	];
	if (
		arrOfStates.find(el => el === "selected") !== undefined &&
		arrOfStates.find(el => el === "default") === undefined
	) {
		bingoResult.corner_bingo.push(1);
	}
	//check diagonals
	let primary = 0;
	let secondary = 0;
	let atLeastOnePrimarySelected = false;
	let atLeastOneSecondarySelected = false;

	for (let i = 0; i < cardTicket.length; i++) {
		if (cardTicket[i][i].state === "selected" || cardTicket[i][i].state === "bingoed") {
			primary++;
			if (cardTicket[i][i].state === "selected") atLeastOnePrimarySelected = true;
		}
		if (
			cardTicket[i][cardTicket.length - 1 - i].state === "selected" ||
			cardTicket[i][cardTicket.length - 1 - i].state === "bingoed"
		) {
			secondary++;
			if (cardTicket[i][cardTicket.length - 1 - i].state === "selected") atLeastOneSecondarySelected = true;
		}
	}

	if (primary === 5 && atLeastOnePrimarySelected) {
		bingoResult.diagonal_bingo.push("primary");
	}
	if (secondary === 5 && atLeastOneSecondarySelected) {
		bingoResult.diagonal_bingo.push("secondary");
	}

	//check fullhouse
	// if (bingoResult.row_bingo.length === 5 || bingoResult.col_bingo.length === 5) {
	// 	bingoResult.full_house.push(1);
	// }

	//MIGHT NEED SOME REVISION HERE CUZ WE'RE NOT CHECKING IF ATLEAST ONE CELL IS SELECTED
	let totalFullHouse = 0;
	for (let i = 0; i < cardTicket.length; i++) {
		for (let j = 0; j < cardTicket[i].length; j++) {
			if (
				cardTicket[i][j].state === "selected" ||
				cardTicket[i][j].state === "bingoed" ||
				cardTicket[i][j].state === "animate"
			) {
				totalFullHouse++;
			}
		}
	}

	if (totalFullHouse === cardTicket.length * cardTicket[0].length) {
		bingoResult.full_house.push(1);
	}

	//multiple bingos
	let multiple_bingo = 0;
	for (const property in bingoResult) {
		if (bingoResult[property].length > 0) {
			multiple_bingo += bingoResult[property].length;
		}
	}
	if (multiple_bingo > 1) {
		// console.log("MULTIPLE_BINGOS!!!!", multiple_bingo);
		bingoResult.multiple_bingo.push(multiple_bingo);
	}
	return bingoResult;
};

export const generateTicket = (rows = 5, cols = 5) => {
	let cardTicket = [];
	let colsCount = 0;
	for (let i = 0; i < cols; i++) {
		cardTicket[i] = new Array(rows);
	}
	for (let i = 0; i <= 60; i += 15) {
		let nums = new Set();
		while (nums.size !== 5) {
			nums.add(Math.floor(Math.random() * 15) + i + 1);
		}
		nums = [...nums];
		for (let k = 0; k < cols; k++) {
			if (k === Math.floor(cols / 2) && colsCount === Math.floor(cols / 2)) {
				cardTicket[k][colsCount] = {
					val: nums[k],
					state: "bingoed",
				};
				continue;
			}
			cardTicket[k][colsCount] = {
				val: nums[k],
				state: "default",
			};
		}
		colsCount++;
	}
	return cardTicket;
};

export const checkIfValid = (val, balls) => {
	if (balls.slice(0, latestBall?.currentBallIndex + 1).find(el => val === el) !== undefined) {
		return true;
	} else return false;
};

export const checkIfPerfectDaub = (val, balls) => {
	if (latestBall?.val === val) return true;
	return false;
};

export const checkIfQuickDaub = val => {
	if (latestBall?.val === val && Date.now() < latestBall?.arrivedAt + 1500) {
		return true;
	}
	return false;
};

export const getBallChar = ball => {
	if (ball <= 15) {
		return "B";
	} else if (ball > 15 && ball <= 30) {
		return "I";
	} else if (ball > 30 && ball <= 45) {
		return "N";
	} else if (ball > 45 && ball <= 60) {
		return "G";
	} else if (ball > 60 && ball <= 75) {
		return "O";
	}
};

export const delay = ms =>
	new Promise(resolve => {
		setTimeout(() => {
			resolve();
		}, ms);
	});

export const getColor = page => {
	if (page === "GAME") return "purple";
	else return "blue";
};

export const fancyTimeFormat = duration => {
	// Hours, minutes and seconds
	var mins = Math.floor((duration % 3600) / 60);
	var secs = Math.floor(duration % 60);

	// Output like "1:01" or "4:03:59" or "123:03:59"
	var ret = "";
	ret += "0" + mins + ":" + (secs < 10 ? "0" : "");
	ret += "" + secs;
	return ret;
};

export const calculateScore = (eventsObj, startFromZero = false) => {
	let initialCoins = startFromZero === true ? 0 : 0;
	//scoring here
	const scoring = {
		correct_daub: 2,
		incorrect_daub: -1,
		row_bingo: 10,
		col_bingo: 10,
		incorrect_bingo: -5,
		full_house: 0,
		diagonal_bingo: 10,
		corner_bingo: 10,
		multiple_bingo: 5,
	};
	let score = initialCoins;
	for (const singleObj of eventsObj)
		for (const key in singleObj) {
			if (key === "correct_daub" || key === "incorrect_daub" || key === "incorrect_bingo") {
				score += singleObj[key] * scoring[key];
			} else if (
				key === "row_bingo" ||
				key === "col_bingo" ||
				key === "full_house" ||
				key === "diagonal_bingo" ||
				key === "corner_bingo"
			) {
				score += singleObj[key].length * scoring[key];
			} else if (key === "multiple_bingo") {
				for (const bin of singleObj[key]) {
					score += scoring[key] * bin - 5;
				}
			}
		}

	return score;
};

export const changeAnnouncement = (latestAnnouncement, duration = 1000) => {
	if (latestAnnouncement && Date.now() > latestAnnouncement.createdAt + duration) {
		return;
	}
	if (latestAnnouncement.message === "Time's Up") {
		duration = 12000;
	}

	announcementStore.set(latestAnnouncement);
	let runTimeoutFor = latestAnnouncement.createdAt + duration - Date.now();
	if (runTimeoutFor >= 0) {
		if (announcementTimer) {
			clearTimeout(announcementTimer);
		}
		announcementTimer = setTimeout(() => {
			announcementStore.set({
				message: "",
				createdAt: 1,
			});
		}, runTimeoutFor);
	}
};

export const createCoinAnimation = (correct, noOfCoins) => {
	let id = uuidv4();
	coinAnimationStore.update(old => {
		return [
			...old,
			{
				correct,
				noOfCoins,
				id: id,
			},
		];
	});
};

export const getCrossFade = () => {
	return crossfade({
		duration: d => Math.sqrt(d * 200),

		fallback(node, params) {
			const style = getComputedStyle(node);
			const transform = style.transform === "none" ? "" : style.transform;

			return {
				duration: 800,
				easing: quintOut,
				css: t => `
					transform: ${transform} ;
					opacity: ${t}
				`,
			};
		},
	});
};

export const getParsedValue = val => {
	try {
		if (val === null) {
			val = true;
		} else {
			val = JSON.parse(val);
		}
		return val;
	} catch (err) {
		console.log(err);
	}
};

export const createAnnouncement = async message => {
	if (message.length === 0) {
		return;
	}
	let newAnnouncement = {
		message,
		createdAt: firebase.database.ServerValue.TIMESTAMP,
	};

	let newAnnouncementRef = await dbAnnouncements().push();
	await newAnnouncementRef.set(newAnnouncement);
};

export let preLoadAssets = async page => {
	if (page === "GAME") {
		let img1 = new Image();
		let img2 = new Image();
		let img3 = new Image();
		let img4 = new Image();
		let img5 = new Image();
		let audio1 = new Audio("/music/NewBall.wav");
		let audio2 = new Audio("/music/BingoToLeaderboard.wav");
		let audio3 = new Audio("/music/CorrectBingo.wav");
		let audio4 = new Audio("/music/Wrong.wav");
		let audio5 = new Audio("/music/Timer321.mp3");
		img1.src = "/images/BingoBalls/B.svg";
		img2.src = "/images/BingoBalls/I.svg";
		img3.src = "/images/BingoBalls/N.svg";
		img4.src = "/images/BingoBalls/G.svg";
		img5.src = "/images/BingoBalls/O.svg";
	} else if (page === "CHOOSE_MODE") {
		let img1 = new Image();
		let img2 = new Image();
		img1.src = "/images/easyTicket.svg";
		img2.src = "/images/hardTicket.svg";
	}
};

export async function endRound() {
	await dbGameTimer().child("ended").set(true);
	await dbHostAction().set({
		action: "End Round",
		time: Date.now(),
	});
	await dbGameSessionRoundValue.transaction(count => {
		return count + 1;
	});
}

export const flattenTicket = cardTicket => {
	let flatCardTicket = [[], []];
	for (let j = 0; j < cardTicket.length; j++) {
		for (let i = 0; i < cardTicket[j].length; i++) {
			flatCardTicket[j].push(...cardTicket[j][i]);
		}
	}
	return flatCardTicket;
};

export const formatTicket = cardTicket => {
	let bigCardTicket = [[], []];
	for (let j = 0; j < cardTicket.length; j++) {
		for (let i = 0; i < cardTicket[j].length; i += 5) {
			bigCardTicket[j].push(cardTicket[j].slice(i, i + 5));
		}
	}
	return bigCardTicket;
};

export const rulesData = [
	{
		heading: "How to Play",
		rows: [
			{
				text: "Watch out for the Numbers rolling in & daub them on your ticket(s).",
				src: "/images/rules/rules-1.svg",
			},
			{
				text: "If you get a BINGO pattern on your Ticket - Hit Bingo",
				src: "./images/rules/rules-2.svg",
			},
			{
				text: "BINGO Patterns: Corners  -  Diagonal  -  Row - Column",
				src: "./images/rules/rules-3.svg",
			},
		],
	},
	{
		heading: "Scoring  Coins",
		rows: [
			{
				text: "For each Correct BINGO Pattern",
				src: "./images/rules/rules-4.svg",
			},
			{
				text: "Bonus Points for Multiple BINGO",
				src: "./images/rules/rules-5.svg",
			},
			{
				text: "For each Correct Daub",
				src: "./images/rules/rules-6.svg",
			},
		],
	},
	{
		heading: "Beware",
		rows: [
			{
				text: "Wrong Daub (-1) & False Bingo Call (-5)cost coins.",
				src: "./images/rules/rules-7.svg",
			},
		],
	},
];
