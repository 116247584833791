<script lang="ts">
	import CellViewOnly from "./CellViewOnly.svelte";
	export let cardTicket;
	export let mini = false;
	let wrongCall = false;
	let nodisplay = true;
	const rows = 5;
	const cols = 5;
</script>

<div class="wrapper" class:mini>
	<div class:wrongCall class:nodisplay>
		<div class="wrongCall">
			<img src="./images/ThumbsDown.svg" alt="thums down" style="width:71px;height:71px;" />
			<p style="font-family:'Pally';font-weight:700;font-size:48px;margin-bottom: 4rem;">BAD CALL!</p>
		</div>
	</div>
	<div class="cardWrapper bingo">
		<div id="b">
			<p>B</p>
		</div>
		<div id="i">
			<p>I</p>
		</div>
		<div id="n">
			<p>N</p>
		</div>
		<div id="g">
			<p>G</p>
		</div>
		<div id="o">
			<p>O</p>
		</div>
	</div>
	<div class="cardWrapper">
		{#if cardTicket?.length > 0}
			{#each Array(cols) as _, i}
				{#each Array(rows) as _, j}
					<!-- change this to cardSTore -->
					<CellViewOnly {i} {j} val={cardTicket[i][j].val} state={cardTicket[i][j].state} {mini} />
				{/each}
			{/each}
		{:else}
			<p>Loading ticket...</p>
		{/if}
	</div>
</div>

<style>
	div.wrongCall {
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: flex-end;
		background: rgba(239, 73, 133, 0.75);
		position: absolute;
		width: 100%;
		height: 85%;
		color: white;
		left: 0;
		top: 0;
	}

	.cardWrapper {
		display: flex;
		justify-content: center;
		align-items: center;
		flex-wrap: wrap;
		width: 100%;
		border-radius: 6.3366px;
		/* box-shadow: 0px 4px 4px rgba(0, 0, 0, 1); */
	}

	.cardWrapper div {
		flex: 1 1 20%;
		height: auto;
		display: flex;
		justify-content: space-around;
		align-items: center;
		background-color: black;
	}

	.cardWrapper div p {
		text-align: center;
	}

	.cardWrapper div div {
		background-color: red;
		border-radius: 50%;
		margin: 0.25rem;
		padding: 1rem;
		font-size: 21px;
	}

	.cardWrapper.bingo {
		width: 100%;
		flex: 0;

		font-size: 35px;
		font-weight: 700;
	}
	.cardWrapper.bingo > * + * {
		margin-left: 2px;
	}
	.cardWrapper.bingo div {
		width: 50px;
		height: 39px;
		border: 1px solid black;
		flex: 1;
		border-radius: 3.6px;
	}
	.wrapper {
		width: 268px;
		height: 318px;
		padding: 8.8px;
		background: linear-gradient(180deg, #3a3a3a 0%, #0b0b0b 100%);
		position: relative;
		border-radius: 6.3366px;
	}
	p {
		margin: 0;
	}
	.nodisplay {
		display: none;
	}
	#b {
		background-color: #368fe0;
	}
	#i {
		background-color: #e51a63;
	}
	#n {
		background-color: #ffffff;
		color: rgba(240, 51, 75, 1);
	}
	#g {
		background-color: #5ecd7a;
	}
	#o {
		background-color: #e5b91a;
	}

	.wrapper.mini {
		width: 183px;
		height: 187px;
		padding: 3px;
	}

	.wrapper.mini > .cardWrapper.bingo {
		font-size: 26px;
	}
	.wrapper.mini > .cardWrapper.bingo div {
		height: 25px;
	}
</style>
