<script>
	import { onDestroy, onMount } from "svelte";
	export let mode;
	let pieInterval;
	let loader, border;
	let alpha = 0,
		pi = Math.PI,
		t = 30;
	onMount(() => {
		loader = document.getElementById("loader");
		border = document.getElementById("border");
		draw();
	});
	onDestroy(() => {
		clearInterval(pieInterval);
	});
	function draw() {
		alpha += mode === "hard" ? 3.705 : 3.2;
		if (alpha > 360) {
			alpha = 0;
			clearInterval(pieInterval);
			return;
		}
		alpha %= 360;
		var r = (alpha * pi) / 180,
			x = Math.sin(r) * 125,
			y = Math.cos(r) * -125,
			mid = alpha > 180 ? 1 : 0,
			anim = "M 0 0 v -125 A 125 125 1 " + mid + " 1 " + x + " " + y + " z";

		loader.setAttribute("d", anim);
		border.setAttribute("d", anim);

		pieInterval = setTimeout(draw, t);
	}
</script>

<svg viewbox="0 0 250 250">
	<path id="border" transform="translate(125, 125)" />
	<path id="loader" transform="translate(125, 125) scale(.84)" />
</svg>

<style>
	svg {
		display: block;
		margin: 50px auto;
		position: absolute;
		left: 0px;
		margin: 10px;
		width: 75px;
		height: 75px;
	}

	#loader {
		fill: #7f4cea;
	}

	#border {
		fill: #ffffffbf;
	}

	@media screen and (max-width: 500px) {
		svg {
			width: 65px;
			height: 65px;
		}
	}
	@media screen and (max-width: 350px) {
		svg {
			width: 53px;
			height: 53px;
		}
	}
</style>
