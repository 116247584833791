<script>
	import { onDestroy } from "svelte";
	import { onMount } from "svelte/internal";
	import Card from "../components/Card.svelte";
	import GameHeader from "../components/GameHeader.svelte";
	import { ExitModalScreen } from "da-components/v0";
	import {
		dbAnnouncements,
		dbGameBalls,
		dbGameCardTicket,
		dbGameMode,
		dbGameTimer,
		listenOnFirebaseKey,
	} from "../services/database";
	import {
		calculateScore,
		changeAnnouncement,
		formatTicket,
		endRound,
		flattenTicket,
		generateTicket,
		getBallChar,
		getCrossFade,
		msecondsSince,
		secondsLeft,
	} from "../services/utils";
	import {
		announcementStore,
		ballStore,
		cardStore,
		coinAnimationStore,
		eventStore,
		exitModalStore,
		latestBallStore,
	} from "../store";
	import { flip } from "svelte/animate";
	import Coin from "../components/Coin.svelte";
	import { playSound } from "../services/audio";
	import PieTimer from "../components/PieTimer.svelte";
	import HardModeMobile from "../components/HardModeMobile.svelte";

	export let isHost;
	export let innerWidth;
	let interval, ballTimeout3seconds;
	let mode;
	let maxBalls;
	let ballArriveSeconds = 3500;
	let cardTicket = [];
	let visibleBalls = [];
	let balls = [];
	let coinsList = [];
	let timeLeft;
	let disabled = false;
	let currentBallIndex = -1;
	let timer321played = false;
	let endGamePlayed = false;
	let dbGameBallsRef, dbAnnouncementsRef, dbGameCardTicketRef, dbGameCurrentBallRef, dbGameTimerRef;
	const [send, receive] = getCrossFade();

	const unsubscribeCoinAnimationStore = coinAnimationStore.subscribe(val => {
		coinsList = val;
	});

	$: {
		latestBallStore.set({ val: balls[currentBallIndex], arrivedAt: Date.now(), currentBallIndex });
		if (maxBalls) {
			if (currentBallIndex >= 0 && currentBallIndex + 1 < maxBalls) {
				visibleBalls = [].concat(balls.slice(0, currentBallIndex + 1)).reverse();
				playSound("NEWBALL");
			} else if (currentBallIndex >= 0) {
				visibleBalls = [].concat(balls.slice(currentBallIndex + 1 - maxBalls, currentBallIndex + 1)).reverse();
				playSound("NEWBALL");
			}
		}
	}

	$: {
		if (mode) {
			maxBalls = mode === "easy" ? 5 : 3;
		}
	}
	const ballStoreUnsubscribe = ballStore.subscribe(value => {
		balls = Object.values(value);
	});
	onMount(() => {
		dbGameMode().once("value", snap => {
			if (!snap.exists() || snap.val() == null) {
				return;
			}
			mode = snap.val();
			maxBalls = mode === "easy" ? 5 : 3;
			ballArriveSeconds = mode === "easy" ? 3500 : 3000;
		});
		dbGameBallsRef = dbGameBalls();
		listenOnFirebaseKey(dbGameBallsRef.child("ballsList"), val => {
			ballStore.set(val);
		});
		dbGameTimerRef = dbGameTimer();
		dbGameTimerRef.on("value", snapshot => {
			if (!snapshot.exists() || snapshot.val() === null) {
				return;
			}
			clearInterval(interval);
			clearTimeout(ballTimeout3seconds);
			const { duration, startAt, ended } = snapshot.val();
			const timeL = secondsLeft(duration, startAt);
			timeLeft = timeL;
			disabled = Math.floor(timeLeft) < 1 || ended;
			let msecondsPassed = Math.max(0, msecondsSince(startAt));
			let ballTime = Math.max(0, startAt - Date.now());
			if (Date.now() > startAt) {
				ballTime = ballArriveSeconds - (msecondsPassed % ballArriveSeconds);
				currentBallIndex = Math.floor(msecondsPassed / ballArriveSeconds);
			}
			ballTimeout3seconds = setTimeout(function ballTimeoutFn() {
				if (ended || timeLeft < 1 || currentBallIndex > balls?.length - 1) {
					clearTimeout(ballTimeout3seconds);
					return;
				}
				msecondsPassed = Math.max(0, msecondsSince(startAt));
				currentBallIndex = Math.floor(msecondsPassed / ballArriveSeconds);
				ballTime = ballArriveSeconds - (msecondsPassed % ballArriveSeconds);
				clearTimeout(ballTimeout3seconds);
				ballTimeout3seconds = setTimeout(ballTimeoutFn, ballTime);
			}, ballTime);
			interval = setInterval(() => {
				const timeL = secondsLeft(duration, startAt);
				timeLeft = timeL;
				disabled = Math.floor(timeLeft) < 1 || ended;
				if (Math.floor(timeLeft) === 3 || (Math.ceil(timeLeft) === 3 && !timer321played)) {
					timer321played = true;
					playSound("TIMER321");
				} else if (Math.floor(timeLeft) <= 0) {
					playSound("TIMER321STOP");
				}
				if (timeL < 1 || ended) {
					if (!endGamePlayed) {
						playSound("ENDGAME");
						endGamePlayed = true;
					}
					clearInterval(interval);
				}
			}, 1000);
		});
		dbAnnouncementsRef = dbAnnouncements();
		listenOnFirebaseKey(dbAnnouncementsRef, val => {
			let allAnnouncements = Object.values(val);
			let latestAnnouncement = allAnnouncements[allAnnouncements.length - 1];
			changeAnnouncement(latestAnnouncement, 3000);
		});
		dbGameCardTicketRef = dbGameCardTicket();
		listenOnFirebaseKey(
			dbGameCardTicketRef,
			//if card exists
			val => {
				cardStore.set(formatTicket(val));
			},
			//if card doesn't already exist
			() => {
				dbGameMode().once("value", async snap => {
					if (!snap.exists()) {
						console.log("mode doesn't exist");
						return;
					}
					if (snap.val() === "easy") {
						await dbGameCardTicket().set(flattenTicket([generateTicket()]));
					} else if (snap.val() === "hard") {
						await dbGameCardTicket().set(flattenTicket([generateTicket(), generateTicket()]));
					}
				});
			}
		);
	});

	onDestroy(() => {
		clearInterval(interval);
		clearTimeout(ballTimeout3seconds);
		dbAnnouncementsRef.off("value");
		dbGameCardTicketRef.off("value");
		dbGameBallsRef.off("value");
		dbGameTimerRef.off("value");
		unsubscribeCoinAnimationStore();
		ballStoreUnsubscribe();
		playSound("BINGOTOLEADERBOARD");
	});
</script>

<div class="GameScreenRoot">
	<div class="GameHeaderWrapper">
		<GameHeader score={Math.max(0, calculateScore($eventStore))} time={timeLeft} {isHost} />
	</div>
	<div class="masterWrapper">
		<div class="ballsWrapper">
			{#if visibleBalls.length > 0}
				{#key currentBallIndex}
					<PieTimer {mode} />
				{/key}
				{#each visibleBalls as ball (ball)}
					<div in:receive|local={{ key: ball }} out:send|local={{ key: ball }} animate:flip class="ballDiv">
						<span>{ball}</span>
						<img
							src={`./images/BingoBalls/${getBallChar(ball)}.svg`}
							alt={"ball image"}
							class="bingo-ball"
						/>
					</div>
				{/each}
			{/if}
		</div>

		<div class="announcements">
			{#if $announcementStore.message.length > 0}
				<p class={$announcementStore?.color || ""}>{$announcementStore.message}</p>
			{/if}
		</div>

		{#if mode === "easy"}
			{#if $cardStore?.length > 0}
				<div class="cardsWrapper">
					<Card key={0} {disabled} />
					<div class="single-coin-wrapper">
						{#each $coinAnimationStore as coin (coin.id)}
							<Coin correct={coin.correct} noOfCoins={coin.noOfCoins} id={coin.id} />
						{/each}
					</div>
				</div>
			{:else}
				loading
			{/if}
			<!-- hard mode -->
		{:else if mode === "hard" && $cardStore?.length > 0}
			{#if innerWidth > 500}
				<div class="cardsWrapper">
					<Card key={0} {disabled} />
					<Card key={1} {disabled} />
					<div class="multiple-coin-wrapper">
						{#each $coinAnimationStore as coin (coin.id)}
							<Coin correct={coin.correct} noOfCoins={coin.noOfCoins} id={coin.id} />
						{/each}
					</div>
				</div>
			{:else}
				<HardModeMobile {disabled} />
			{/if}
		{/if}
	</div>
</div>

{#if $exitModalStore === true}
	<div class="exitModalWrapper">
		<ExitModalScreen
			gameOrRound="round"
			onMouseDown={() => playSound("CLICK")}
			startNew={() => {
				endRound();
				exitModalStore.set(false);
			}}
			goBack={() => {
				exitModalStore.set(false);
			}}
		/>
	</div>
{/if}

<style>
	.GameScreenRoot {
		width: 100%;
		height: 100%;
		display: flex;
		background-color: var(--base);
		background-image: url("/images/backgrounds/blue-bg.svg");
		background-position: center;
		background-size: cover;
	}
	.GameHeaderWrapper {
		z-index: 2;
	}
	.masterWrapper {
		width: 100%;
		/* height: 100%; */
		margin-top: 100px;
		/* position: absolute; */
		text-align: center;
		display: flex;
		justify-content: center;
		align-items: center;
		flex-direction: column;
		left: 50%;
		/* transform: translate(-50%); */
	}
	.announcements {
		max-width: 390px;
		width: 92%;
		margin: auto;
		min-height: 39px;
		display: flex;
		justify-content: center;
		align-items: center;

		font-weight: 700;
		font-size: 24px;
	}
	@keyframes fadeAndScale {
		from {
			opacity: 0;
			transform: scale(0.1, 0.1);
		}
		to {
			opacity: 1;
			transform: scale(1, 1);
		}
	}
	.announcements p {
		animation-duration: 0.3s;
		animation-name: fadeAndScale;
		animation-timing-function: linear;
	}

	.bingo-ball {
		width: 60px;
	}
	span {
		width: 50px;
		height: 50px;
		text-align: center;
		border-radius: 50%;
		display: flex;
		justify-content: center;
		align-items: center;
		position: absolute;
		left: 50%;
		top: 50%;
		transform: translate(-50%, -50%);
		border: none;

		font-size: 16px;
		font-weight: 700;
	}
	.ballDiv {
		position: relative;
		height: 75px;
		width: 75px;
		display: flex;
		justify-content: center;
		align-items: center;
	}
	.ballsWrapper {
		display: flex;
		color: black;
		flex-direction: row;
		justify-content: center;
		margin: auto;
		/* margin-top: 3rem; */
		/* border: 2px solid white; */
		padding: 10px;
		max-width: 400px;
		/* min-width: 105px; */
		width: max-content;
		border-radius: 100px;
		align-items: center;
		/* background: rgba(255, 255, 255, 0.12); */
		height: 99px;
		position: relative;
	}
	.ballsWrapper div:nth-child(2) {
		border-radius: 50%;
	}
	.ballsWrapper div:nth-child(2) > img {
		width: 70px;
	}
	.ballsWrapper div:nth-child(2) > span {
		font-size: 20px;
	}
	.cardsWrapper > :global(*) + :global(*) {
		margin-left: 16px;
	}

	.cardsWrapper {
		display: flex;
		justify-content: center;
		/* margin-top: 18px; */
		flex: 1;
		align-items: center;
		/* position: relative; */
	}

	.red {
		color: #f86593;
	}

	.exitModalWrapper {
		position: absolute;
		width: 100%;
		height: 100%;
		left: 0;
		top: 0;
		z-index: 3;
	}

	.single-coin-wrapper,
	.multiple-coin-wrapper {
		height: 318px;
	}

	/* responsive code */
	/* @media screen and (max-width: 850px) {
		.ballsWrapper {
			margin-top: 2rem;
		}
	}
	@media screen and (max-width: 750px) {
		.ballsWrapper {
			margin-top: 2rem;
		}
	} */
	@media screen and (max-width: 500px) {
		.ballDiv {
			width: 64px;
			height: 64px;
		}
		.ballDiv img {
			width: 100%;
			height: 100%;
		}
		.ballDiv span {
			font-size: 14px;
		}

		.single-coin-wrapper,
		.multiple-coin-wrapper {
			margin-left: 0;
		}
		.ballsWrapper div:nth-child(2) > img {
			width: 64px;
		}
		.ballsWrapper div:nth-child(2) > span {
			font-size: 14px;
		}

		.announcements {
			display: none;
		}
	}
	@media screen and (max-width: 350px) {
		.ballDiv {
			width: 52px;
			height: 52px;
		}
		.ballDiv img {
			width: 100%;
			height: 100%;
		}
	}
</style>
