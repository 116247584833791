<script>
	import { onDestroy, onMount } from "svelte";
	import CardViewOnly from "../components/CardViewOnly.svelte";
	import { dbFullHouse, dbGameLeaderboardRef, dbGameMode, listenOnFirebaseKey, user } from "../services/database";
	import { Confetti } from "da-components";
	import { usersStore } from "../store";
	import Header from "../components/Header.svelte";
	import { formatTicket } from "../services/utils";
	export let isHost;
	let cardTicket;
	let mini = false;
	let mode;
	let winner;
	let confettiTimeOut;
	let dbGameLeaderboardRefOff;
	$: userName = $usersStore[winner?.winnerId]?.userName;

	onMount(() => {
		dbGameLeaderboardRefOff = dbGameLeaderboardRef();
		listenOnFirebaseKey(dbGameLeaderboardRefOff.child("winner"), val => {
			winner = val;
			if (winner?.winnerId === user.id) {
				console.log("here");
				Confetti.startSpawning();
				confettiTimeOut = setTimeout(function () {
					Confetti.stopSpawning();
					Confetti.removeCanvas();
				}, 5000);
			}
		});
		dbGameMode().once("value", snap => {
			if (!snap.exists() || snap.val() == null) {
				return;
			}
			mode = snap.val();
		});
	});
	onDestroy(() => {
		dbGameLeaderboardRefOff.off("value");
		clearTimeout(confettiTimeOut);
		Confetti?.stopSpawning();
		Confetti?.removeCanvas();
	});
</script>

<div class="winnerScreenRoot">
	<div class="headerWrapper">
		<Header {isHost} />
	</div>
	<div class="WinnerSectionWrapper">
		{#if userName}
			<p>{userName} Has Won The Game!</p>
			<div class="cardWrapper">
				{#if mode === "easy"}
					{#if winner && winner.cardTicket.length > 0}
						<CardViewOnly cardTicket={formatTicket(winner.cardTicket)[0]} />
					{/if}
				{:else if mode === "hard"}
					{#if winner && winner.cardTicket.length > 1}
						<CardViewOnly cardTicket={formatTicket(winner.cardTicket)[0]} />
						<CardViewOnly cardTicket={formatTicket(winner.cardTicket)[1]} />
					{/if}
				{/if}
			</div>
		{/if}
	</div>
</div>

<style>
	.winnerScreenRoot {
		height: 100%;
		width: 100%;
		position: relative;
		background-color: rgba(127, 76, 234, 1);
		background-image: url(/images/backgrounds/purple-bg.svg);
		background-position: center;
		background-size: cover;
	}
	.headerWrapper {
		z-index: 2;
		height: 100px;
	}

	.WinnerSectionWrapper {
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
		margin: auto;
		width: 100%;
		/* height: 100%; */
	}
	.WinnerSectionWrapper > p {
		font-weight: 700;
		font-size: 32px;
		text-align: center;
	}
	.cardWrapper {
		margin-top: 19px;
		display: flex;
	}
	.cardWrapper > :global(*) + :global(*) {
		margin-left: 1rem;
	}

	@media screen and (max-height: 560px) {
		.WinnerSectionWrapper {
			position: static;
			height: 100%;
			transform: translate(0, 0);
			justify-content: flex-start;
			/* margin-top: 2rem; */
		}
	}
</style>
