<script lang="ts">
	import { onDestroy, onMount } from "svelte";
	import { playSound } from "../services/audio";
	import { Confetti } from "da-components";
	import {
		dbFullHouse,
		dbGameCardTicket,
		dbGameEvents,
		dbGameTimer,
		dbPage,
		getRoundValue,
		user,
	} from "../services/database";
	import {
		calculateScore,
		checkBingo,
		createAnnouncement,
		createCoinAnimation,
		delay,
		flattenTicket,
		getParsedValue,
	} from "../services/utils";

	import { cardStore, defaultEventStoreData, eventStore, firstTimeStore, gameTipsStore } from "../store";
	import Cell from "./Cell.svelte";
	import InfoPrompt from "./InfoPrompt.svelte";
	export let key;
	export let disabled = false;
	const animationms = 400;
	const rows = 5;
	const cols = 5;
	let animateTimeout;
	let cardTicket;
	let coinAnimation = true;
	let confettiTimeOut;
	let invalidBingoTimeout;
	let wrongCall = false;
	let nodisplay = true;
	let infoPrompt = false;
	let infoPromptTimerRef;
	let info = "";
	let emojiCode;
	let roundValue = getRoundValue();
	let firstTimeEvents;
	let gameEventsObj;

	let showMissedBingoTip = localStorage.getItem("da-bingo-firstTimeTips-missed_bingo") || true;
	showMissedBingoTip = getParsedValue(showMissedBingoTip);

	const firstUnsubscribe = firstTimeStore.subscribe(value => {
		firstTimeEvents = value;
	});

	const launchInfoPrompt = prop => {
		infoPrompt = true;
		info = $firstTimeStore[prop].info;
		emojiCode = $firstTimeStore[prop].emojiCode;
		clearTimeout(infoPromptTimerRef);
		infoPromptTimerRef = setTimeout(() => {
			infoPrompt = false;
			info = "";
		}, 5000);
	};
	// cardStore.set(cardTicket);
	const unsubscribeGameEvents = eventStore.subscribe(value => {
		gameEventsObj = value[key];
		//add key here value[key]
	});
	const unsubscribeCardStore = cardStore.subscribe(value => {
		cardTicket = value[key];
	});
	onDestroy(() => {
		clearTimeout(confettiTimeOut);
		clearTimeout(infoPromptTimerRef);
		clearTimeout(animateTimeout);
		clearTimeout(invalidBingoTimeout);
		Confetti?.stopSpawning();
		Confetti?.removeCanvas();
		unsubscribeCardStore();
		unsubscribeGameEvents();
		firstUnsubscribe();
	});
	//fetch or make new
	// put in onMount
	onMount(() => {
		dbGameEvents().once("value", async snap => {
			//this logs eventStore of previous round if present
			if (snap.val() === null) {
				//change this to default variant of eventstore
				// await dbGameEvents().set($eventStore);
				// console.log("default event store data", $eventStore);
				eventStore.set(JSON.parse(JSON.stringify(defaultEventStoreData)));
				await dbGameEvents().set($eventStore);
				return;
			}
			// eventStore has both eventObjs
			//updating content of event store on reload/first time load
			//snap.val() gets us data stored at server(assumed to be latest)
			//...gameEventsObj adds data such as empty arrays which firebase doesn't store but we need at client side
			eventStore.update(oldEvents => {
				return [
					{ ...gameEventsObj, ...snap.val()[0] },
					{ ...gameEventsObj, ...snap.val()[1] },
				];
			});
			// eventStore.update(eventObj => {
			// 	//fix this update function to match 2 eventObjs
			// 	console.log(eventObj);
			// 	const otherEventObj = eventObj[key === 0 ? 1 : 0];
			// 	if (key === 0) {
			// 		return [{ ...gameEventsObj[key], ...gameEventsObj }, otherEventObj];
			// 	} else if (key === 1) {
			// 		return [otherEventObj, { ...gameEventsObj[key], ...gameEventsObj }];
			// 	}
			// 	// return {
			// 	// 	...$eventStore,
			// 	// 	...snap.val(),
			// 	// };
			// });
			// console.log("end");
		});
	});

	const fullHouseDetected = async (userId, cardTicketIndex, userName, cardTicket) => {
		// announcement

		await Promise.all([
			createAnnouncement("Someone got the Full House!"),
			dbGameTimer().child("ended").set(true),
			delay(5 * 1000),
		]);

		await dbFullHouse().set({ userId, cardTicketIndex, userName, cardTicket: flattenTicket([cardTicket]) });
		//change this to happen from firebase
		await dbPage().set("FULL_HOUSE");
	};

	const updateDaubs = async action => {
		if (disabled) return;
		if (action === 1) {
			//correct daub;
			//fix this event update
			gameEventsObj.correct_daub = gameEventsObj.correct_daub + 1;
			updateEventStore();
			// eventStore.update(eventObj => {
			// 	console.log(eventObj);
			// 	return {
			// 		...eventObj,
			// 		correct_daub: eventObj.correct_daub + 1,
			// 	};
			// });
			// dbGameEvents().update($eventStore);
		} else if (action === -1) {
			// incorrect daub
			//fix this event update
			gameEventsObj.incorrect_daub = gameEventsObj.incorrect_daub + 1;
			updateEventStore();
			// eventStore.update(eventObj => {
			// 	return {
			// 		...eventObj,
			// 		incorrect_daub: eventObj.incorrect_daub + 1,
			// 	};
			// });
		}
		await dbGameEvents().set($eventStore);
	};

	const updateCellStatus = async (i, j, status) => {
		if (disabled) return;
		//save cellStatus in firebase by increasing daub once
		//update cardTicket in firebase TODO\
		cardTicket[i][j].state = status;
		cardTicket = cardTicket;
		updateCardTicket();
	};
	const updateEventStore = () => {
		eventStore.update(eventObj => {
			//fix this update function to match 2 eventObjs
			const otherEventObj = eventObj[key === 0 ? 1 : 0];
			if (key === 0) {
				return [gameEventsObj, otherEventObj || {}];
			} else if (key === 1) {
				return [otherEventObj || {}, gameEventsObj];
			}
			// return {
			// 	...$eventStore,
			// 	...snap.val(),
			// };
		});
	};

	const updateCardStore = () => {
		cardStore.update(t => {
			const otherCardTicket = t[key === 0 ? 1 : 0];
			//handle when otherCardTicket is undefined
			if (key === 0) {
				return [cardTicket, otherCardTicket || []];
			} else if (key === 1) {
				return [otherCardTicket || [], cardTicket];
			}
		});
	};

	const updateCardTicket = async () => {
		updateCardStore();
		await dbGameCardTicket().set(flattenTicket($cardStore));
		//check for missed_bingo
		if (showMissedBingoTip) {
			const bingoCheck = checkBingo(cardTicket);
			let bingoValid = false;
			for (const property in bingoCheck) {
				if (bingoCheck[property].length > 0) {
					bingoValid = true;
					break;
				}
			}
			if (bingoValid === true) {
				await delay(200);
				if (
					$gameTipsStore &&
					firstTimeEvents?.currentRound === getRoundValue() &&
					firstTimeEvents?.missed_bingo.shown === false
				) {
					launchInfoPrompt("missed_bingo");
					// localStorage.setItem("da-bingo-firstTimeTips-missed_bingo", false);
					showMissedBingoTip = false;
					firstTimeStore.update(old => {
						return {
							...old,
							missed_bingo: {
								...old.missed_bingo,
								shown: true,
							},
						};
					});
				}
			}
		}
	};

	const updateBingo = async (bingoUpdates, incorrect_bingo = false) => {
		//add key here
		if (incorrect_bingo === false) {
			for (let prop in bingoUpdates) {
				if (prop !== "multiple_bingo") {
					gameEventsObj[prop] = [...new Set([...gameEventsObj[prop], ...bingoUpdates[prop]])];
				} else {
					gameEventsObj[prop] = [...gameEventsObj[prop], ...bingoUpdates[prop]];
				}
			}
		} else {
			gameEventsObj["incorrect_bingo"] = gameEventsObj["incorrect_bingo"] + 1;
		}
		updateEventStore();

		await dbGameEvents().set($eventStore);
		// check for full house here
		if (gameEventsObj?.full_house?.length > 0) {
			//full house detected
			// console.log("/////////full house detected");
			fullHouseDetected(user.id, key, user.userName, cardTicket);
		}
	};
	const handleBingo = () => {
		if (disabled) return;
		// checkBingo()
		//return early if no new bingo TODO
		const bingoResults = checkBingo(cardTicket);
		let bingoValid = false;
		for (const property in bingoResults) {
			if (bingoResults[property].length > 0) {
				bingoValid = true;
				break;
			}
		}
		if (bingoValid === false) {
			nodisplay = false;
			wrongCall = true;
			invalidBingoTimeout = setTimeout(() => {
				nodisplay = true;
				wrongCall = false;
			}, 1500);
			playSound("WRONG");
			if (calculateScore($eventStore) >= 5) {
				createCoinAnimation(false, 5);
				let bingoUpdates = {};
				updateBingo(bingoUpdates, true);
			}
			return;
		}
		//play confetti here
		clearTimeout(confettiTimeOut);
		Confetti.startSpawning();
		confettiTimeOut = setTimeout(function () {
			Confetti.stopSpawning();
			Confetti.removeCanvas();
		}, 3000);

		playSound("CORRECTBINGO");
		let bingoUpdates = {};
		for (const property in bingoResults) {
			if (bingoResults[property].length > 0) {
				bingoUpdates[property] = bingoResults[property];
			}
		}

		for (const property in bingoResults) {
			if (bingoResults[property].length > 0) {
				switch (property) {
					case "col_bingo":
						for (const p of bingoResults[property]) {
							for (let i = 0; i < cols; i++) {
								cardTicket[i][p].state = "animate";
							}
						}
						break;
					case "row_bingo":
						for (const p of bingoResults[property]) {
							for (let i = 0; i < cols; i++) {
								cardTicket[p][i].state = "animate";
							}
						}
						break;
					case "corner_bingo":
						for (const p of bingoResults[property]) {
							cardTicket[0][cols - 1].state = "animate";
							cardTicket[cols - 1][cols - 1].state = "animate";
							cardTicket[cols - 1][0].state = "animate";
							cardTicket[0][0].state = "animate";
							cardTicket[Math.floor(cols / 2)][Math.floor(cols / 2)].state = "animate";
						}
						break;
					case "diagonal_bingo":
						for (const p of bingoResults[property]) {
							if (p === "primary") {
								for (let i = 0; i < cardTicket.length; i++) {
									cardTicket[i][i].state = "animate";
								}
							} else {
								for (let i = 0; i < cardTicket.length; i++) {
									cardTicket[i][cardTicket.length - 1 - i].state = "animate";
								}
							}
						}
						break;
				}
			}
		}
		// animation
		animateTimeout = setTimeout(async () => {
			for (let i = 0; i < cols; i++) {
				for (let j = 0; j < rows; j++) {
					if (cardTicket[i][j].state === "animate") cardTicket[i][j].state = "bingoed";
				}
			}
			cardTicket = cardTicket;
			await updateCardTicket();
		}, animationms);
		updateBingo(bingoUpdates);

		//update announcement?
		if (user?.userName.length > 0) {
			createAnnouncement(user.userName + " hit a Bingo!");
		} else {
			createAnnouncement("Someone hit a Bingo!");
		}
		//get total new coins to be added and start coin animation
		let newCoins = calculateScore([bingoUpdates, {}], true);
		createCoinAnimation(true, newCoins);
		// change color
	};
</script>

<div class="wrapper">
	{#if infoPrompt}
		<InfoPrompt {info} {emojiCode} left={key === 0} />
	{/if}

	<div class:wrongCall class:nodisplay>
		<div>
			<img src="./images/ThumbsDown.svg" alt="thums down" />
			<p>BAD CALL!</p>
		</div>
	</div>
	<div class="cardWrapper bingo">
		<div id="b">
			<p>B</p>
		</div>
		<div id="i">
			<p>I</p>
		</div>
		<div id="n">
			<p>N</p>
		</div>
		<div id="g">
			<p>G</p>
		</div>
		<div id="o">
			<p>O</p>
		</div>
	</div>
	<div class="cardWrapper">
		{#if cardTicket?.length > 0}
			{#each Array(cols) as _, i}
				{#each Array(rows) as _, j}
					<!-- change this to cardSTore -->
					<Cell
						{i}
						{j}
						val={cardTicket[i][j].val}
						state={cardTicket[i][j].state}
						{disabled}
						{updateCellStatus}
						{updateDaubs}
						{launchInfoPrompt}
					/>
				{/each}
			{/each}
		{:else}
			<p>Loading ticket...</p>
		{/if}
	</div>

	<div class="bingoWrapper">
		<button on:click={handleBingo} class:wrongCall> BINGO </button>
	</div>
</div>

<style>
	div.wrongCall {
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: flex-end;
		background: rgba(239, 73, 133, 0.75);
		position: absolute;
		width: 100%;
		height: 85%;
		color: white;
		left: 0;
		top: 0;
		border-top-left-radius: 6.333px;
		border-top-right-radius: 6.333px;
	}
	div.wrongCall img {
		width: 71px;
		height: 71px;
	}

	div.wrongCall p {
		font-family: "Pally";
		font-weight: 700;
		font-size: 48px;
		margin-bottom: 4rem;
	}

	.cardWrapper {
		display: flex;
		justify-content: center;
		align-items: center;
		flex-wrap: wrap;
		width: 100%;
		border-radius: 6.3366px;
		/* box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25); */
	}

	.cardWrapper div {
		flex: 1 1 20%;
		height: auto;
		display: flex;
		justify-content: space-around;
		align-items: center;
		background-color: black;
	}

	.cardWrapper div p {
		text-align: center;
	}

	.cardWrapper div div {
		background-color: red;
		border-radius: 50%;
		margin: 0.25rem;
		padding: 1rem;
		font-size: 21px;
	}

	.cardWrapper.bingo {
		width: 100%;
		flex: 0;

		font-size: 41px;
		font-weight: 700;
	}
	.cardWrapper.bingo > * + * {
		margin-left: 3px;
	}
	.cardWrapper.bingo div {
		width: 50px;
		height: 39px;
		border: 1px solid black;
		flex: 1;
		border-radius: 3.6px;
	}
	.wrapper {
		width: 268px;
		height: 318px;
		padding: 8px;
		background: linear-gradient(180deg, #3a3a3a 0%, #0b0b0b 100%);
		position: relative;
		border-radius: 6.3366px;
	}
	.bingoWrapper {
		width: 100%;
	}
	.bingoWrapper button {
		width: 100%;
		color: white;
		border: rgba(0, 0, 0, 1) 2.4px solid;
		background: linear-gradient(180deg, rgba(0, 0, 0, 0.25) 0%, rgba(255, 255, 255, 0) 100%), #ffae02;
		background-blend-mode: soft-light, normal;
		border-radius: 21.7255px;
		height: 40px;
		cursor: pointer;

		font-size: 24px;
		font-weight: 700;
		padding: 0;
		margin: 0;
	}
	button.wrongCall {
		background: rgba(229, 26, 99, 1);
		animation: shake 0.82s cubic-bezier(0.36, 0.07, 0.19, 0.97) both 0.1s;
	}
	@keyframes shake {
		10%,
		90% {
			transform: translate3d(-1px, 0, 0);
		}
		20%,
		80% {
			transform: translate3d(2px, 0, 0);
		}
		30%,
		50%,
		70% {
			transform: translate3d(-4px, 0, 0);
		}
		40%,
		60% {
			transform: translate3d(4px, 0, 0);
		}
	}
	p {
		margin: 0;
	}

	#b {
		background-color: #368fe0;
	}
	#i {
		background-color: #e51a63;
	}
	#n {
		background-color: #ffffff;
		color: rgba(240, 51, 75, 1);
	}
	#g {
		background-color: #5ecd7a;
	}
	#o {
		background-color: #e5b91a;
	}

	.nodisplay {
		display: none;
	}

	@media screen and (max-height: 575px) {
		.wrapper {
			width: 219px;
			height: 259px;
		}
		.cardWrapper.bingo div {
			height: 33px;
			font-size: 30px;
		}
		.bingoWrapper button {
			height: 32.5px;
			font-size: 21px;
		}
		div.wrongCall img {
			width: 60px;
			height: 60px;
		}
		div.wrongCall p {
			font-size: 36px;
		}

		.bingoWrapper button {
			font-size: 20px;
		}
	}
</style>
