<script lang="ts">
	import { onDestroy, onMount } from "svelte";
	import { coinAnimationStore, coinCounterBindStore } from "../store";
	import { spring } from "svelte/motion";

	export let noOfCoins = "";
	export let correct;
	export let id;
	let coinsAnimationBind;
	let coords;
	let springTimeout;
	let coinAnimationStoreTimeout;
	const unsubscribeCoinCounterBindStore = coinCounterBindStore.subscribe(val => {
		if (coords) coords.set({ x: val.left, y: val.top });
	});
	onMount(() => {
		//get boundingclientrect of  coin counter
		springTimeout = setTimeout(() => {
			if (coinsAnimationBind) {
				coords = spring(
					{
						x: coinsAnimationBind.getBoundingClientRect().left,
						y: coinsAnimationBind.getBoundingClientRect().top,
					},
					{
						stiffness: 0.01,
						damping: 0.11,
					}
				);
			}
			if (coinsAnimationBind && $coinCounterBindStore !== undefined) {
				coords.set({ x: $coinCounterBindStore.left - 24, y: $coinCounterBindStore.top });
			}
		}, 500);
		coinAnimationStoreTimeout = setTimeout(() => {
			coinAnimationStore.update(old => {
				return old.filter(animation => animation.id !== id);
			});
		}, 1500);
	});

	onDestroy(() => {
		clearTimeout(springTimeout);
		clearTimeout(coinAnimationStoreTimeout);
		unsubscribeCoinCounterBindStore();
	});
	$: actualLeft = coords !== undefined ? $coords.x + "px" : "auto";
	$: actualTop = coords !== undefined ? $coords.y + "px" : "50%";
</script>

{#if correct}
	<div class="correct" style={`left:${actualLeft};top:${actualTop}`} bind:this={coinsAnimationBind}>
		<img src="./images/coins.svg" alt="coins" />
		+{noOfCoins}
	</div>
{:else}
	<div class="wrong" style={`left:${actualLeft};top:${actualTop}`} bind:this={coinsAnimationBind}>
		<img src="./images/coins.svg" alt="coins" />
		-{noOfCoins}
	</div>
{/if}

<style>
	.correct,
	.wrong {
		position: fixed;
		width: 80px;
		/* height: 37px; */
		font-family: "Poppins";
		/* background: #e61a63; */
		/* border: 2px solid #392f5a; */
		box-sizing: border-box;
		/* border-radius: 49.5225px; */
		display: flex;
		justify-content: center;
		align-items: center;
		font-size: 14px;

		font-weight: 700;
	}

	.correct img,
	.wrong img {
		width: 19px;
		height: 19px;
		margin-right: 2px;
	}

	.wrong {
		/* background: red; */
		color: #f86593;
	}
</style>
