<script>
	import { Header } from "da-components/v0";
	import { rulesModalStore, sound } from "../store";
	import { playSound } from "../services/audio";
	export let isHost = false;
	let isSoundOn = false;
	let hostRoundController = false;
	let showModal = false;

	async function endRound() {
		playSound("CLICK");
		await dbGameSessionRoundValue.transaction(count => {
			return count + 1;
		});
	}

	$: soundState = $sound;
	$: rulesState = $rulesModalStore;

	$: if (soundState === false) {
		playSound("MUTE");
		isSoundOn = false;
	} else {
		isSoundOn = true;
		playSound("UNMUTE");
	}

	const soundButtonHandler = isSoundOn => {
		if (isSoundOn === true) {
			isSoundOn = true;
			sound.update(() => true);
			playSound("CLICK");
		} else if (isSoundOn === false) {
			playSound("CLICK");
			isSoundOn = false;
			sound.update(() => false);
		}
	};
	const rulesHandler = () => {
		rulesModalStore.set(!rulesState);
	};
</script>

<Header
	showRules={true}
	{rulesHandler}
	gameNameSvg="./images/bingo-header-logo.svg"
	{isHost}
	{soundButtonHandler}
	{isSoundOn}
	onMouseDown={() => playSound("CLICK")}
	themeSupported={false}
/>

<style>
</style>
