<script lang="ts">
	export let info = "";
	export let emojiCode = "";
	export let left = false;
</script>

<div class:shiftLeft={left} class:shiftRight={!left}>
	{#if emojiCode.length > 0}
		<span>
			{emojiCode}
		</span>
	{/if}
	<p>{info}</p>
</div>

<style>
	div {
		z-index: 2;
		position: absolute;
		top: 33%;
		width: 160px;
		padding: 10px;
		color: black;
		background: white;
		font-family: "Poppins";
		font-size: 14px;
		border: 2px solid #392f5a;
		box-sizing: border-box;
		box-shadow: 4px 4px 0px rgba(0, 0, 0, 0.3);
		border-radius: 12px;
		line-height: 130%;
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
	}

	span {
		font-size: 24px;
	}

	p {
		text-align: center;
		margin-top: 10px;
	}

	.shiftLeft {
		right: 105%;
	}

	.shiftRight {
		left: 105%;
	}
</style>
