import { writable } from "svelte/store";
export const defaultEventStoreData = [
	{
		correct_daub: 0,
		incorrect_daub: 0,
		row_bingo: [],
		col_bingo: [],
		corner_bingo: [],
		diagonal_bingo: [],
		incorrect_bingo: 0,
		multiple_bingo: [],
		full_house: [],
	},
	{
		correct_daub: 0,
		incorrect_daub: 0,
		row_bingo: [],
		col_bingo: [],
		corner_bingo: [],
		diagonal_bingo: [],
		incorrect_bingo: 0,
		multiple_bingo: [],
		full_house: [],
	},
];

export const defaultFirstTimeEventsStoreData = {
	currentRound: 1,
	missed_ball: {
		shown: false,
		info: "Your number was called, dont forget to mark it",
		emojiCode: "💡",
	},
	wrong_daub: {
		shown: false,
		info: "This is your first warning for daubing a wrong number. Next time 1 point will be deducted.",
		emojiCode: "⚠️",
	},
	missed_bingo: {
		shown: false,
		info: "You got a pattern, click on bingo button to get points.",
		emojiCode: "🎊",
	},
};
export const sound = writable(true);
export const usersStore = writable([]);
export const notification = writable();
export const cardStore = writable();
export const ballStore = writable([]);
export const latestBallStore = writable();
export const coinAnimationStore = writable([]);
export const coinCounterBindStore = writable();
export const exitModalStore = writable(false);
export const rulesModalStore = writable(true);
export const gameTipsStore = writable(true);
export const announcementStore = writable({
	message: "",
	createdAt: 1,
});
export const firstTimeStore = writable(defaultFirstTimeEventsStoreData);
//the value of the store need to be set on every round to defaultEventStoreData but if  we dont do deep copy the  value from previous round is persisted in the next  round
export const eventStore = writable(JSON.parse(JSON.stringify(defaultEventStoreData)));
