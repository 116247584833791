<script lang="ts">
	import { onDestroy } from "svelte";
	import App from "../App.svelte";
	import { getRoundValue } from "../services/database";
	import { playSound } from "../services/audio";
	import {
		calculateScore,
		changeAnnouncement,
		checkIfPerfectDaub,
		checkIfQuickDaub,
		checkIfValid,
		createCoinAnimation,
		delay,
		getParsedValue,
	} from "../services/utils";
	import { ballStore, eventStore, firstTimeStore, gameTipsStore, latestBallStore } from "../store";
	export let i = 0;
	export let j = 0;
	export let updateCellStatus;
	export let updateDaubs;
	export let val = 0;
	export let state = "default";
	export let launchInfoPrompt;
	export let disabled = false;
	let promptTimeoutRef;
	let roundValue = getRoundValue();
	let balls = [];
	let firstTimeEvents;
	let showWrongDaubTip = localStorage.getItem("da-bingo-firstTimeTips-wrong_daub") || true;
	let showMissedBallTip = localStorage.getItem("da-bingo-firstTimeTips-missed_ball") || true;

	showWrongDaubTip = getParsedValue(showWrongDaubTip);
	showMissedBallTip = getParsedValue(showMissedBallTip);

	const firstUnsubscribe = firstTimeStore.subscribe(value => {
		firstTimeEvents = value;
	});
	const ballStoreUnsubscribe = ballStore.subscribe(value => {
		balls = Object.values(value);
	});

	const latestBallStoreUnsubscribe = latestBallStore.subscribe(async value => {
		//CHECK FOR MISSED_BALL RIGHT AFTER A NEW BALL IS ADDED
		if (showMissedBallTip && $gameTipsStore && firstTimeEvents?.missed_ball.shown === false) await delay(1000);
		if (
			state === "default" &&
			$gameTipsStore &&
			checkIfValid(val, balls) &&
			showMissedBallTip &&
			firstTimeEvents?.missed_ball.shown === false
		) {
			launchInfoPrompt("missed_ball");
			firstTimeStore.update(old => {
				// localStorage.setItem("da-bingo-firstTimeTips-missed_ball", false);
				showMissedBallTip = false;
				return {
					...old,
					missed_ball: {
						...old.missed_ball,
						shown: true,
					},
				};
			});
			let oldState = state;
			state = "missed_daub";
			promptTimeoutRef = setTimeout(() => {
				state = oldState;
			}, 5000);
		}
	});

	onDestroy(() => {
		clearTimeout(promptTimeoutRef);
		ballStoreUnsubscribe();
		firstUnsubscribe();
		latestBallStoreUnsubscribe();
	});
	$: selected = state === "selected";
	$: bingoAnimation = state === "animate";
	$: bingoed = state === "bingoed";
	$: wrongDaub = state === "wrong_daub";
	$: missedDaub = state === "missed_daub";
	const select = () => {
		if (disabled) return;
		if (state === "selected" || state === "bingoed" || state === "animate" || state === "wrong_daub") return;
		// if(not incorrect)
		if (!(i === 2 && j === 2) && checkIfValid(val, balls) === true) {
			// console.log("correct");
			//check if that was quick
			playSound("CLICK");
			if (checkIfQuickDaub(val)) {
				changeAnnouncement({ message: "That was Quick!", createdAt: Date.now() }, 1000);
				//check if this  is perfect daub
			} else if (checkIfPerfectDaub(val, balls)) {
				changeAnnouncement({ message: "Perfect Daub!", createdAt: Date.now() }, 1000);
			}
			createCoinAnimation(true, 2);
			updateDaubs(1);
			updateCellStatus(i, j, "selected");
			if (promptTimeoutRef) {
				clearTimeout(promptTimeoutRef);
			}
			firstTimeStore.update(old => {
				return {
					...old,
					missed_ball: {
						...old.missed_ball,
						shown: true,
					},
				};
			});
			selected = true;
		} else {
			//check if first time and round ===1 AND CHECK FOR WRONG_DAUB
			playSound("WRONG");
			if (showWrongDaubTip && $gameTipsStore && firstTimeEvents?.wrong_daub.shown === false) {
				launchInfoPrompt("wrong_daub");
				//create first time wrong daub announcement here
				changeAnnouncement({ message: "Oops. Be Careful!", createdAt: Date.now() }, 1000);
				firstTimeStore.update(old => {
					// localStorage.setItem("da-bingo-firstTimeTips-wrong_daub", false);
					showWrongDaubTip = false;
					return {
						...old,
						wrong_daub: {
							...old.wrong_daub,
							shown: true,
						},
					};
				});
				let oldState = state;
				state = "wrong_daub";
				promptTimeoutRef = setTimeout(() => {
					state = oldState;
				}, 5000);
			} else {
				if (promptTimeoutRef) {
					clearTimeout(promptTimeoutRef);
				}
				let oldState = state;
				state = "wrong_daub";
				promptTimeoutRef = setTimeout(() => {
					state = oldState;
				}, 300);
				if (calculateScore($eventStore) >= 1) {
					createCoinAnimation(false, 1);
					changeAnnouncement({ message: "Ouch, that Cost!", createdAt: Date.now(), color: "red" }, 1000);
					// console.log("incorrect");
					updateDaubs(-1);
				} else {
					changeAnnouncement(
						{ message: "Bad Call!, Be Careful!", createdAt: Date.now(), color: "red" },
						1000
					);
				}
			}
		}
		// else dedcut scores
	};
</script>

{#if i === 2 && j === 2}
	<div on:click={select} class:bingoAnimation>
		<div class:selected class:bingoed class="center">
			<!-- <p>:)</p> -->
			<img src="./images/Star.svg" alt="center star" />
		</div>
	</div>
{:else}
	<div on:click={select} class:bingoAnimation>
		<div class:selected class:bingoed class:missedDaub class:wrongDaub>
			<p>{val}</p>
		</div>
	</div>
{/if}

<style>
	div {
		flex: 1 1 17%;
		height: auto;
		display: flex;
		justify-content: space-around;
		align-items: center;
		color: white;
		transition: linear 0.1s;
	}

	.center > img {
		width: 65%;
		height: 65%;
	}

	.selected {
		background-color: #e6b91a;
		border-radius: 50%;
		color: #392f5a;
	}
	.bingoAnimation {
		/* background-color: green; */
		border-radius: 50%;
		transform: scale(1.3);
	}
	.bingoed {
		background-color: #f9850b;
		border-radius: 7px;
		color: white;
	}
	.center {
		background-color: red;
	}

	.wrongDaub {
		background: #e61a63;
		border-radius: 50%;
	}
	.missedDaub {
		border: 2px dashed #e6b91a;
		box-sizing: border-box;
		border-radius: 1.22131e9px;
		color: #e6b91a;
	}

	div p {
		text-align: center;
		font-size: 17px;

		font-weight: 700;
	}
	p {
		margin: 0;
	}
	div div {
		cursor: pointer;
		margin: 6px;
		height: 33px;
		/* padding: 1rem; */
		font-size: 21px;
	}

	@media screen and (max-height: 575px) {
		div div {
			margin: 4px;
			height: 28px;
		}
	}
</style>
